<template>
  <div>

    <div class="row">

      <div class="col-12 q-pb-sm">

        <BaseInput
          label="Nome"
          error-name="firstname"
          :errors="errors"
          v-model="user.name">

          <template #prepend>
            <q-icon name="mdi-account-circle-outline" />
          </template>

        </BaseInput>

      </div>

      <div class="col-12 q-pb-md">

        <BaseInput
          type="email"
          label="Email"
          error-name="email"
          :errors="errors"
          disable
          :value="user.email">

          <template #prepend>
            <q-icon name="mdi-email-outline" />
          </template>

          <template #hint>
            Modifica della mail temporanemente disabilitata
          </template>

        </BaseInput>

      </div>

    </div>

    <!-- default slot -->
    <slot />

  </div>
</template>

<script>
import useVModel from '../../hooks/vModel.js'

export default{
  name: 'UserForm',

  props: {
    value: {
      type: Object,
    },
    errors : {
      type: Object,
    },
  },

  setup( props, ctx ){
    const { vModel: user } = useVModel( props.value, ctx )

    return {
      user,
    }
  },

}
</script>

