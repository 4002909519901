<template>

  <BasePage
    padding
    :title="user.name"
    subtitle="gestione dati personali">

    <BaseCard
      class="q-mt-lg">

      <q-splitter
        v-model="splitterModel"
      >

        <template v-slot:before>

          <q-tabs
            v-model="tab"
            @input="value => $router.push( { params: { tab : value } } )"
            vertical
            :disabled="loading"
            class="text-primary">
            <q-tab name="main" icon="mdi-account-circle-outline" label="Dati personali" />
            <q-tab name="password" icon="mdi-lock-outline" label="Modifica password" />
          </q-tabs>

        </template>

        <template v-slot:after>

          <q-tab-panels
            v-model="tab"
            :disabled="loading"
            animated
            swipeable
            vertical
            transition-prev="jump-up"
            transition-next="jump-up">

            <q-tab-panel name="main">

              <UserForm
                :errors="errors"
                v-model="state.user">
                <BaseBtn
                  label="Salva"
                  :disabled="loading"
                  @click="update" />
              </UserForm>

            </q-tab-panel>

            <q-tab-panel name="password">

              <PasswordForm
                :errors="errors"
                v-model="state.passwords">
                <BaseBtn
                  label="Salva"
                  :disabled="loading"
                  @click="updatePassword" />
              </PasswordForm>

            </q-tab-panel>

          </q-tab-panels>

        </template>

      </q-splitter>

    </BaseCard>

  </BasePage>

</template>

<script>
import PasswordForm from '../components/users/PasswordForm.vue';
import UserForm from '../components/users/UserForm.vue';
import useApiErrors from '../hooks/apiErrors.js'
import useAuth from '../hooks/auth.js'
import useNotify from '../hooks/notify.js'
import useToggler from '../hooks/toggler.js'
import { reactive, ref, watch } from '@vue/composition-api'

export default {
  name : 'Profile',

  components: {
    PasswordForm,
    UserForm,
  },

  setup(_,{ root }){
    const tab = ref('')
    const splitterModel= ref( 20 )
    const { toggler : loading, on, off } = useToggler()
    const { user, passwordUpdate } = useAuth()
    const { success } = useNotify()

    const errors = useApiErrors()

    const state = reactive({
      user: { ...user.value},
      passwords: {
        current_password : '',
        password : '',
        password_confirmation : '',
      },
    })

    function update()
    {
      on()
      setTimeout( () => off(), 2000 )
    }

    function updatePassword()
    {
      const { current_password, password, password_confirmation } = state.passwords

      on()
      passwordUpdate( current_password, password, password_confirmation )
        .then( () => {
          success( 'Password modificata correttamente' )
          errors.clearError()
        })
        .catch( errors.setError )
        .finally( off )
    }

    watch(
      () => root.$route.params.tab,
      value => tab.value = value || 'main',
      { immediate: true }
    )

    return {
      errors,
      splitterModel,
      tab,
      state,
      loading,
      user,
      update,
      updatePassword,
    }
  },

}
</script>

