<template>
  <div>

    <div class="row">

      <div class="col-12 q-pb-md">

        <BaseInput
          color="primary"
          label="Password attuale"
          error-name="current_password"
          :errors="errors"
          v-model="passwords.current_password"
          :type="showCurrentPassword ? 'text' : 'password'"
          :rules="[val => !!val || 'Campo Obbligatorio']">

          <template #prepend>
            <q-icon name="mdi-lock-outline" />
          </template>

          <template #append>
            <q-icon
              :name="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
              class="cursor-pointer"
              @click="toggleCurrentPassword" />
          </template>

        </BaseInput>

      </div>

      <div class="col-12 q-pb-md">

        <BaseInput
          color="primary"
          label="Scegli una nuova password"
          error-name="password"
          :errors="errors"
          v-model="passwords.password"
          :type="showPassword ? 'text' : 'password'"
          :rules="[val => !!val || 'Campo Obbligatorio']">

          <template #prepend>
            <q-icon name="mdi-lock-outline" />
          </template>

          <template #append>
            <q-icon
              :name="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              class="cursor-pointer"
              @click="togglePassword" />
          </template>

        </BaseInput>

      </div>

      <div class="col-12 q-pb-md">

        <BaseInput
          color="primary"
          label="Conferma la nuova password"
          error-name="password_confirmation"
          :errors="errors"
          v-model="passwords.password_confirmation"
          :type="showPasswordConfirmation ? 'text' : 'password'"
          :rules="[val => !!val || 'Campo Obbligatorio']">

          <template #prepend>
            <q-icon name="mdi-lock-outline" />
          </template>

          <template #append>
            <q-icon
              :name="showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
              class="cursor-pointer"
              @click="togglePasswordConfirmation" />
          </template>

        </BaseInput>

      </div>

    </div>

    <!-- default slot -->
    <slot />

  </div>
</template>

<script>
import useVModel from '../../hooks/vModel.js'
import useToggler from '../../hooks/toggler.js'

export default{
  name: 'PasswordForm',

  props: {
    value: {
      type: Object,
    },
    errors : {
      type: Object,
    },
  },

  setup( props, ctx ){

    const { vModel: passwords } = useVModel( props.value, ctx )
    const { toggler : showCurrentPassword, toggle: toggleCurrentPassword } = useToggler()
    const { toggler : showPassword, toggle: togglePassword } = useToggler()
    const { toggler : showPasswordConfirmation, toggle: togglePasswordConfirmation } = useToggler()

    return {
      passwords,
      showCurrentPassword,
      showPassword,
      showPasswordConfirmation,
      toggleCurrentPassword,
      togglePassword,
      togglePasswordConfirmation,
    }
  },

}
</script>

